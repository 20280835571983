import { PilotageDirection } from "apina-pilot-online";

export function isDirectionInbound(direction: PilotageDirection): boolean {
    switch (direction) {
        case PilotageDirection.INBOUND:
        case PilotageDirection.INBOUND_ANCHORAGE:
            return true;
        case PilotageDirection.OUTBOUND:
        case PilotageDirection.OUTBOUND_ANCHORAGE:
        case PilotageDirection.TRANSIT:
        case PilotageDirection.PORT_TO_PORT:
        case PilotageDirection.SHIFT:
            return false;
    }
}

