import { ChangeDetectionStrategy, Component, effect, Input, signal } from "@angular/core";
import { AirDraft, Draft, Drafts, VesselDraftBounds, VesselEndpoint, VesselId } from "apina-frontend";
import { BoundsPipe, copyGreatestValue, greaterThanOrEqual, MAX_DRAFT_METERS, unsubscribeWhenDestroyed, validateAirDraft, validateDraft, VESSEL_MAX_HEIGHT } from "common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@Component({
    selector: "app-edit-draft-fields",
    templateUrl: "./edit-draft-fields.component.html",
    styles: `
        :host {
            flex: 1 0 0;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        BoundsPipe,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
    host: {
        'class': 'flex justify-between gap-2',
    }
})
export class EditDraftFieldsComponent {

    @Input({required: true})
    controls!: DraftFields;

    private readonly _vesselDraftBounds = signal<VesselDraftBounds | null>(null);

    /** Draft bounds to display. Alternatively vesselId can be passed in, in which case bounds are fetched automatically */
    @Input()
    set vesselDraftBounds(vesselDraftBounds: VesselDraftBounds | null) {
        this._vesselDraftBounds.set(vesselDraftBounds);
    }

    private readonly _vesselId = signal<VesselId | null>(null);

    /** Id of vessel whose draft bounds to show */
    @Input()
    set vesselId(vesselId: VesselId | null) {
        this._vesselId.set(vesselId);
    }

    @Input()
    required = false;

    readonly maxDraft = MAX_DRAFT_METERS;
    readonly maxAirDraft = VESSEL_MAX_HEIGHT;

    readonly effectiveDraftBounds = signal<VesselDraftBounds | null>(null);

    constructor(vesselEndpoint: VesselEndpoint) {
        effect(() => {
            const draftBounds = this._vesselDraftBounds();
            const vesselId = this._vesselId();

            if (draftBounds != null)
                this.effectiveDraftBounds.set(draftBounds);
            else if (vesselId != null)
                vesselEndpoint.findVesselById(vesselId).subscribe(v => this.effectiveDraftBounds.set(v.draftBounds));
            else
                this.effectiveDraftBounds.set(null);
        }, {allowSignalWrites: true});
    }
}

interface DraftFields {
    draft: FormControl<Draft | null>;
    draftFore: FormControl<Draft | null>;
    draftAft: FormControl<Draft | null>;
    airDraft: FormControl<AirDraft | null>;
}

export function createDraftControls(drafts?: Drafts): DraftFields {
    const controls: DraftFields = {
        draft: new FormControl<Draft | null>(drafts?.max ?? null, validateDraft),
        draftFore: new FormControl<Draft | null>(drafts?.fore ?? null, validateDraft),
        draftAft: new FormControl<Draft | null>(drafts?.aft ?? null, validateDraft),
        airDraft: new FormControl<AirDraft | null>(drafts?.air ?? null, validateAirDraft),
    };

    unsubscribeWhenDestroyed(copyGreatestValue<Draft | null>(controls.draft, [controls.draftAft, controls.draftFore]));

    controls.draft.addValidators([greaterThanOrEqual(controls.draftAft), greaterThanOrEqual(controls.draftFore)]);
    unsubscribeWhenDestroyed(controls.draftAft.valueChanges.subscribe(() => controls.draft.updateValueAndValidity()));
    unsubscribeWhenDestroyed(controls.draftFore.valueChanges.subscribe(() => controls.draft.updateValueAndValidity()));

    return controls;
}
