import { PilotageDirection, TosConflictReason } from "apina-frontend";
import { Duration } from "@js-joda/core";
import { isDirectionInbound } from "./pilotage-direction";

// TODO: copy-paste from NoticeType.kt
const INBOUND_NOTICE_REQUIRES_DRAFT_LIMIT = Duration.ofHours(26);
const OUTBOUND_NOTICE_REQUIRES_DRAFT_LIMIT = Duration.ofHours(13);

export function firstNoticeTimeForDirection(direction: PilotageDirection): Duration {
    return isDirectionInbound(direction) ? INBOUND_NOTICE_REQUIRES_DRAFT_LIMIT : OUTBOUND_NOTICE_REQUIRES_DRAFT_LIMIT;
}

export const tosConflictReasonLabels: Record<TosConflictReason, string> = {
    CHANGE_NOT_WITHIN_MARGIN: "Muutosta ei ole annettu sallitussa aikaikkunassa",
    ETA_CHANGED_WAY_TOO_MUCH: "ETA-aikaa siirretty enemmän kuin sallittua",
};
